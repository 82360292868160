declare var window: Record<string, Record<string, string>>;
const domainURL = `${window.location.protocol}//api.${window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}`;

const domainIFrameURL = `${window.location.protocol}//iframe.${window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}`;

export const BASE_API_URL_DEVELOPMENT =
    domainURL.indexOf('localhost') > -1
        ? `${window._env.REACT_APP_API_URL}`
        : domainURL;

export const BASE_IFRAME_URL_DEVELOPMENT =
    domainIFrameURL.indexOf('localhost') > -1
        ? `${window._env.REACT_APP_IFRAME_URL}`
        : domainIFrameURL;

const appURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''
    }`;
export const BASE_WEBPAGE_URL =
    appURL.indexOf('localhost') > -1
        ? `${window._env.REACT_APP_DOMAIN}`
        : appURL;
export const PRESET_ENDPOINT_URL = `${BASE_API_URL_DEVELOPMENT}`;

export const LANGUAGE_RESOURCE_URL = appURL.indexOf('localhost') > -1
    ? `${window._env.REACT_APP_DOMAIN}/i18n`
    : 'https://ewssaas2.blob.core.windows.net/i18n';


// const identityURL = `${window.location.protocol}//id.${window.location.hostname
//     }${window.location.port ? `:${window.location.port}` : ''}`;
export const IDENTITY_SERVER_ENDPOINT_URL = `${window._env.REACT_APP_IDENTITY_SERVER_URL}`
// identityURL.indexOf('localhost') > -1
//     ? `${window._env.REACT_APP_IDENTITY_SERVER_URL}`
//     : identityURL;


/* ----------------------------------------------------------------------------------- */
/* ---------------------------------     IFrame    -------------------------------------- */
/* ----------------------------------------------------------------------------------- */
// Add On
export const IFRAME_ADDON_LIST = `${BASE_IFRAME_URL_DEVELOPMENT}/Addon`;
export const IFRAME_ADDON_ADD = `${BASE_IFRAME_URL_DEVELOPMENT}/Addon/Create`;
export const IFRAME_ADDON_EDIT = `${BASE_IFRAME_URL_DEVELOPMENT}/Addon/Edit/{0}`;
export const IFRAME_ADDON_EDIT_SIMPLE = `${BASE_IFRAME_URL_DEVELOPMENT}/Addon/EditSimple/{0}`;

//Item
export const IFRAME_ITEM_LIST = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Items/Items.aspx`;

// Create Fee
export const IFRAME_ADD_FEE = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Items/Wizards/AddItem.aspx?ItemType=Fee`;
export const IFRAME_EDIT_FEE = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Items/Wizards/EditItem.aspx?ItemId={0}`;
export const IFRAME_VIEW_FEE_DETAIL = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Items/Item.aspx?ItemId={0}`;

//Conman Clubs
export const IFRAME_CONMAN_CLUBS = `${BASE_IFRAME_URL_DEVELOPMENT}/Clubs`;
export const IFRAME_CONMAN_ADD_CLUB = `${BASE_IFRAME_URL_DEVELOPMENT}/Club/Create`;
export const IFRAME_CONMAN_EDIT_CLUB = `${BASE_IFRAME_URL_DEVELOPMENT}/Club/Edit/{0}`;

//Promotion
export const IFRAME_PROMOTION_LIST = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Promotions/Promotions.aspx`;
export const IFRAME_ADD_PROMOTION = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Promotions/Wizards/AddPromotion.aspx?AddNewPromotion=true`;
export const IFRAME_EDIT_PROMOTION = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Promotions/Wizards/AddPromotion.aspx?PromotionId={0}`;
export const IFRAME_EDITAVAILABILITY_PROMOTION = `${BASE_IFRAME_URL_DEVELOPMENT}/Products/Promotions/Wizards/EditPromotionAvailability.aspx?PromotionId={0}`;
export const IFRAME_CLUB_BANKING = `${BASE_IFRAME_URL_DEVELOPMENT}/Clubs/ClubBankingSession.aspx?ClubId={0}`;
export const IFRAME_OUTSTANDING_SHIFT = `${BASE_IFRAME_URL_DEVELOPMENT}/Clubs/Shifts.aspx?ClubId={0}`;
export const IFRAME_CLOSE_SHIFT = `${BASE_IFRAME_URL_DEVELOPMENT}/Clubs/ClosedShifts.aspx?ClubId={0}`;
export const IFRAME_CLUB_LIST = `${BASE_IFRAME_URL_DEVELOPMENT}/Clubs/Clubs.aspx`;
export const IFRAME_CLUB_DETAIL = `${BASE_IFRAME_URL_DEVELOPMENT}/Clubs/Club.aspx?ClubId={0}`;

/* ----------------------------------------------------------------------------------- */
/* ---------------------------------     Route    -------------------------------------- */
/* ----------------------------------------------------------------------------------- */

// Plan Route
export const ROUTE_PLAN_LIST = '/products/plans';
export const ROUTE_PLAN_VIEW = '/products/plans/{0}/view-detail';
export const ROUTE_PLAN_EDIT = '/products/plans/{0}/edit';
export const ROUTE_PLAN_QUICK_EDIT = '/products/plans/{0}/quick-edit';
export const ROUTE_PLAN_CLONE = '/products/plans/{0}/clone';
export const ROUTE_PLAN_QUICK_CLONE = '/products/plans/{0}/quick-clone';
export const ROUTE_PLAN_EDIT_AVAILABILITY = '/products/plans/{0}/edit-availability';

// Tender Class Route
export const ROUTE_TENDER_CLASS_LIST = '/system/tender-class';
export const ROUTE_TENDER_CLASS_ADD = '/system/tender-class/create';
export const ROUTE_TENDER_CLASS_EDIT = '/system/tender-class/{0}/edit';
export const ROUTE_TENDER_CLASS_VIEW = '/system/tender-class/{0}/view';

// Tender Type Route
export const ROUTE_TENDER_TYPE_LIST = '/system/tender-type';
export const ROUTE_TENDER_TYPE_ADD = '/system/tender-type/create';
export const ROUTE_TENDER_TYPE_EDIT = '/system/tender-type/{0}/edit';
export const ROUTE_TENDER_TYPE_VIEW = '/system/tender-type/{0}/view';

// General
export const GENERAL_OPTION_PAGE_SIZE = 20;
export const GENERAL_DATE_FORMAT = `DD/MM/YYYY`;
export const GENERAL_DATABASE_DATE_FORMAT = `YYYY-MM-DD`;
export const GENERAL_NA = 'N/A';

/* ----------------------------------------------------------------------------------- */
/* ---------------------------------     API    -------------------------------------- */
/* ----------------------------------------------------------------------------------- */

// Conman Get
export const API_GET_IFRAME_TOKEN = `${IDENTITY_SERVER_ENDPOINT_URL}/id/accounts/token`;
export const API_GET_IFRAME_SUBTENANTS = `${BASE_API_URL_DEVELOPMENT}/acm/subtenants`;

export const API_GET_ADDON_OF_MSB = `${BASE_API_URL_DEVELOPMENT}/mbs/addons?legacyclubid={0}`;

export const API_GET_OPERATOR = `${BASE_API_URL_DEVELOPMENT}/acm/users/{1}/info`;
export const API_GET_LANGUAGE_JSON = `${BASE_API_URL_DEVELOPMENT}/cnm/languages`;
export const API_GET_SUB_TENANT = `${BASE_API_URL_DEVELOPMENT}/api/tenants/{0}/subTenants`;
export const API_GET_SYSTEM_CONFIGS = `${BASE_API_URL_DEVELOPMENT}/cnm/system/configs`;

// Operator Edit
export const API_BULK_IMPORT_OPERATORS = `${BASE_API_URL_DEVELOPMENT}/api/operators/bulkimport`;
export const API_BULK_IMPORT_OPERATORS_PAGINATION = `${BASE_API_URL_DEVELOPMENT}/api/operators/bulkimport`;
export const API_UPDATE_OPERATOR_INFO = `${BASE_API_URL_DEVELOPMENT}/acm/users/{0}/info`;
export const API_UPDATE_OPERATOR_AVATAR = `${BASE_API_URL_DEVELOPMENT}/acm/users/{0}/avatar`;

export const API_OPERATORS_RESET_PASSWORD = `${BASE_API_URL_DEVELOPMENT}/api/operators/resetpassword`;
export const API_FORGOT_PASSWORD = `${BASE_API_URL_DEVELOPMENT}/api/operators/forgot-password`;
export const API_CHANGE_FORGOT_PASSWORD = `${BASE_API_URL_DEVELOPMENT}/api/operators/change-password-forgot`;
export const API_CONFIGS_MEMBER_PASSWORD = `${IDENTITY_SERVER_ENDPOINT_URL}/id/accounts/{0}/password`;

// Plan
export const API_PLAN_GET_PACKAGES = `${BASE_API_URL_DEVELOPMENT}/plm/packages?pageSize=1000`;
export const API_PLAN_GET_CLASSES = `${BASE_API_URL_DEVELOPMENT}/plm/planclasses`;
export const API_PLAN_GET_PAYMENTS = `${BASE_API_URL_DEVELOPMENT}/plm/payments`;
export const API_PLAN_GET_TERMS = `${BASE_API_URL_DEVELOPMENT}/plm/terms`;
export const API_PLAN_GET_SUBSIDIES = `${BASE_API_URL_DEVELOPMENT}/plm/subsidytypes`;
export const API_PLAN_GET_CLUBGROUPS = `${BASE_API_URL_DEVELOPMENT}/plm/clubgroups?pageSize=1000`;
export const API_PLAN_GET_CLUBS = `${BASE_API_URL_DEVELOPMENT}/plm/clubgroups/{0}/clubs?pageSize=1000`;
export const API_PLAN_GET_PACKAGES_PERIODS = `${BASE_API_URL_DEVELOPMENT}/plm/packages/{0}/periods`;
export const API_PLAN_GET_UNITS = `${BASE_API_URL_DEVELOPMENT}/plm/units`;
export const API_PLAN_GET_FIELDS = `${BASE_API_URL_DEVELOPMENT}/plm/fields/editplan`;
export const API_PLAN_GET_DATA = `${BASE_API_URL_DEVELOPMENT}/plm/plans/{0}`;
export const API_PLAN_PUT_DATA = `${BASE_API_URL_DEVELOPMENT}/plm/plans/{0}`;
export const API_PLAN_AVAILABILITY_GET_DATA = `${BASE_API_URL_DEVELOPMENT}/plm/plans/{0}/availability`;
export const API_PLAN_AVAILABILITY_PUT_DATA = `${BASE_API_URL_DEVELOPMENT}/plm/plans/{0}/availability`;
export const API_PLAN_CLONE_POST_DATA = `${BASE_API_URL_DEVELOPMENT}/plm/plans/clone`;
export const API_PLAN_QUICK_CLONE_POST_DATA = `${BASE_API_URL_DEVELOPMENT}/plm/plans/{0}/quickclone`;
export const API_PLAN_QUICK_EDIT_PUT_DATA = `${BASE_API_URL_DEVELOPMENT}/plm/plans/{0}/quickedit`;

// Tender
export const API_CREATE_TENDER_CLASS = `${BASE_API_URL_DEVELOPMENT}/cnm/tenderclasses`;
export const API_GET_UPDATE_TENDER_CLASS_DETAIL = `${BASE_API_URL_DEVELOPMENT}/cnm/tenderclasses/{1}`;
export const API_CREATE_TENDER_TYPE = `${BASE_API_URL_DEVELOPMENT}/cnm/tendertypes`;
export const API_GET_UPDATE_TENDER_TYPE_DETAIL = `${BASE_API_URL_DEVELOPMENT}/cnm/tendertypes/{1}`;
export const API_TENDER_CLASS_GET_FIELDS = `${BASE_API_URL_DEVELOPMENT}/cnm/fields/tenderclass`;
export const API_TENDER_TYPE_GET_FIELDS = `${BASE_API_URL_DEVELOPMENT}/cnm/fields/tendertype`;
export const API_GET_AUTHORIZATION_METHOD = `${BASE_API_URL_DEVELOPMENT}/cnm/tendertypes/authorizationMethods`;
export const API_GET_TENDER_CLASS_SUBTENANTS = `${BASE_API_URL_DEVELOPMENT}/cnm/subtenants`;
export const API_GET_TENDER_TYPE_SUBTENANTS = `${BASE_API_URL_DEVELOPMENT}/cnm/subtenants`;
export const API_GET_TENDER_TYPE_TENDER_CLASSES = `${BASE_API_URL_DEVELOPMENT}/cnm/tenderclasses`;

// Fee
export const API_CREATE_FEE = `${BASE_API_URL_DEVELOPMENT}/plm/fees`;
export const API_GET_UPDATE_FEE_DETAIL = `${BASE_API_URL_DEVELOPMENT}/plm/fees/{1}`;
export const API_GET_FEE_LEDGER_GROUP = `${BASE_API_URL_DEVELOPMENT}/plm/fees/ledgergroups`;
export const API_GET_FEE_TAX_GROUP = `${BASE_API_URL_DEVELOPMENT}/plm/fees/taxgroups`;
export const API_GET_FEE_PRICING_OPTION = `${BASE_API_URL_DEVELOPMENT}/plm/fees/pricingoptions`;
export const API_GET_FEE_TENNDER_RESTRICTION = `${BASE_API_URL_DEVELOPMENT}/plm/fees/tenderrestrictions`;
export const API_GET_FEE_STATUS = `${BASE_API_URL_DEVELOPMENT}/plm/fees/itemstatuses`;
export const API_GET_FEE_ITEM_TYPE = `${BASE_API_URL_DEVELOPMENT}/plm/fees/itemtypes`;
export const API_GET_FEE_TYPE = `${BASE_API_URL_DEVELOPMENT}/plm/fees/feetypes`;

// Tenant
export const API_CONFIGS_GET_TENANT = `${BASE_API_URL_DEVELOPMENT}/cnm/tenants/{0}`;
export const API_CONFIGS_GET_SUBTENANT = `${BASE_API_URL_DEVELOPMENT}/cnm/subtenants/{0}`;
export const API_CONFIGS_GET_TENANT_THEME = `${BASE_API_URL_DEVELOPMENT}/cnm/themes`;

export const API_OPERATORS_SEARCH = `${BASE_API_URL_DEVELOPMENT}/api/operators/search`;

export const API_ROLES = `${BASE_API_URL_DEVELOPMENT}/acm/roles`;
export const API_ROLE_BY_ID = `${BASE_API_URL_DEVELOPMENT}/acm/roles/{0}`;
export const API_SEARCH_ROLES = `${BASE_API_URL_DEVELOPMENT}/acm/roles/search`;
export const API_RESOURCES = `${BASE_API_URL_DEVELOPMENT}/acm/resources/types`;
export const API_RESOURCE_BY_ID = `${BASE_API_URL_DEVELOPMENT}/acm/resources/types/{0}`;
export const API_GET_SUBTENANTS = `${BASE_API_URL_DEVELOPMENT}/acm/subtenants`;
export const API_SEARCH_CLUBGROUP = `${BASE_API_URL_DEVELOPMENT}/acm/tenants/{1}/clubGroups`;
export const API_USERS_FILTER = `${BASE_API_URL_DEVELOPMENT}/acm/users/roles/clubgroups`;
export const API_USERS_GROUP_FILTER = `${BASE_API_URL_DEVELOPMENT}/acm/users/groups/roles/clubgroups`;
export const API_USERS = `${BASE_API_URL_DEVELOPMENT}/acm/users`;
export const API_SEARCH_USERS = `${BASE_API_URL_DEVELOPMENT}/acm/users/search`;
export const API_USER_BY_ID = `${BASE_API_URL_DEVELOPMENT}/acm/users/{0}`;
export const API_SEARCH_USERGROUP_BY_ROLEID = `${BASE_API_URL_DEVELOPMENT}/acm/users/groups/roles`;
export const API_CREATE_PASSWORD = `${BASE_API_URL_DEVELOPMENT}/acm/users/password`;

// User group
export const API_CREATE_USER_GROUP = `${BASE_API_URL_DEVELOPMENT}/acm/users/groups`;
export const API_SEARCH_USER_GROUPS = `${BASE_API_URL_DEVELOPMENT}/acm/users/groups/search`;

// Add New Membership
export const API_MBS_GET_SUBTENANT = `${BASE_API_URL_DEVELOPMENT}/mbs/subtenants`;
// Step 1
export const API_MBS_GET_CLUBS = `${BASE_API_URL_DEVELOPMENT}/mbs/clubs?pageSize={0}`;
export const API_MBS_GET_APPLICATION_TYPES = `${BASE_API_URL_DEVELOPMENT}/mbs/applicationtypes?pageSize={0}`;
export const API_MBS_GET_SALE_PEOPLE = `${BASE_API_URL_DEVELOPMENT}/mbs/salepersons?pageSize={0}`;
export const API_MBS_GET_PACKAGE_PLANS = `${BASE_API_URL_DEVELOPMENT}/mbs/clubs/{0}/plans?pageSize={1}&startDate={2}`;
// Step 3
export const API_MBS_GET_FIELDS_ADD_MEMBER = `${BASE_API_URL_DEVELOPMENT}/mbs/fields/addmember?pageSize={0}`;

export const API_MBS_GET_MEMBER_TITLES = `${BASE_API_URL_DEVELOPMENT}/mbs/titles`;
export const API_MBS_GET_MEMBER_GENDERS = `${BASE_API_URL_DEVELOPMENT}/mbs/genders`;
export const API_MBS_GET_MEMBER_LANGUAGE_PREFERENCES = `${BASE_API_URL_DEVELOPMENT}/mbs/languages`;
export const API_MBS_GET_OVERRIDE_EMAIL_OPTIONS = `${BASE_API_URL_DEVELOPMENT}/mbs/overrideemails`;
export const API_MBS_GET_COUNTRIES = `${BASE_API_URL_DEVELOPMENT}/mbs/countries`;
export const API_MBS_GET_CONTACT_METHODS = `${BASE_API_URL_DEVELOPMENT}/mbs/contactmethods`;
export const API_MBS_GET_MARKETING_SOURCE = `${BASE_API_URL_DEVELOPMENT}/mbs/sources?pageSize={0}`;
//TODO: waiting for api get health questions
export const API_MBS_GET_AIA_VITALITY_CATEGORY = `${BASE_API_URL_DEVELOPMENT}/mbs/aiavitalitycategories`;
export const API_MBS_GET_RECURRING_CARD_TYPE = `${BASE_API_URL_DEVELOPMENT}/mbs/recurringcardtypes`;
export const API_MBS_GET_DUPLICATE_MEMBERS = `${BASE_API_URL_DEVELOPMENT}/mbs/members/duplicates?pageSize={0}&lastName={1}&dateOfBirth={2}&postCode={3}`;
// Step 4
export const API_MBS_GET_MEMBER_BANKS = `${BASE_API_URL_DEVELOPMENT}/mbs/banks`;
export const API_MBS_GET_MEMBER_CARD_TYPES = `${BASE_API_URL_DEVELOPMENT}/mbs/cardtype\s`;
// Step 5
export const API_MBS_POST_MEMBER = `${BASE_API_URL_DEVELOPMENT}/mbs/members`;
export const API_MBS_PUT_MEMBER = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}`;

// Member Summary
export const API_MBS_GET_MEMBER_BY_ID = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}`;
export const API_MBS_GET_PAYMENT_DETAIL = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/paymentdetails`;
export const API_MBS_GET_PAYMENT_METHOD = `${BASE_API_URL_DEVELOPMENT}/mbs/paymentmethods/{id}`;
export const API_MBS_GET_BANK = `${BASE_API_URL_DEVELOPMENT}/mbs/banks`;

// Package detail
export const API_GET_PACKAGE_DETAIL = `${BASE_API_URL_DEVELOPMENT}/plm/packages/{1}`;

// Field configuration
export const API_GET_SUBTENANT_CONMAN = `${BASE_API_URL_DEVELOPMENT}/cnm/subtenants`;
export const API_GET_FEATURES = `${BASE_API_URL_DEVELOPMENT}/cnm/features`;
export const API_GET_FEATURE_DETAIL = `${BASE_API_URL_DEVELOPMENT}/cnm/features/{1}`;
export const API_GET_PUT_FIELDS = `${BASE_API_URL_DEVELOPMENT}/cnm/features/{1}/fields`;

// Look up
export const API_GET_SUBTENANTS_LOOK_UP = `${BASE_API_URL_DEVELOPMENT}/cnm/subtenants`;
export const API_CREATE_LOOK_UP = `${BASE_API_URL_DEVELOPMENT}/cnm/lookups`;
export const API_GET_UPDATE_LOOK_UP_DETAIL = `${BASE_API_URL_DEVELOPMENT}/cnm/lookups/{1}`;

//Change personal details
export const API_MBS_CHANGE_PERSONAL_DETAILS = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/personaldetails`;

// freeze membership
export const API_GET_FREEZE = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/freeze/request`;
export const API_GET_FREEZE_REASON = `${BASE_API_URL_DEVELOPMENT}/mbs/freezes/reasons`;
export const API_CREATE_FREEZE_MEMBERSHIP = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/freeze`;
export const API_GET_UNFREEZE_PREVIEW = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/unfreeze/{freezeId}/preview`;
export const API_UNFREEZE = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/unfreeze/{freezeId}`;
export const API_MODIFY_AND_DELETE_FREEZE = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/freeze/{freezeId}`;

// make payment
export const API_GET_TENDER_TYPES = `${BASE_API_URL_DEVELOPMENT}/mbs/tendertypes`;
export const API_GET_MEMBER_ARREARS = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/arrears`;
export const API_MAKE_PAYMENT = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/arrears`;

// cancel membership
export const API_GET_CANCELLATION_REASONS = `${BASE_API_URL_DEVELOPMENT}/mbs/cancellationreasons`;
export const API_GET_CANCELLATION_DATE = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/cancellationdate?requestDate={requestDate}&overrideCNB={overrideCNB}&overrideMCP={overrideMCP}&overrideMBP={overrideMBP}&overrideEBP={overrideEBP}`;
export const API_GET_CANCELLATION_PAYMENT_SUMMARY = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/cancelmember/paymentsummary?cancellationdate={cancellationDate}`;
export const API_POST_CANCELLATION = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/cancelmember`;

// remove change membership
export const API_REMOVE_CHANGE_MBS = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{memberId}/changeMembership`;

// remove cancel membership
export const API_REMOVE_CANCEL_MEMBERSHIP = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/removecancel/paymentsummary?month={month}`;
export const API_SAVE_REMOVE_CANCEL_MEMBERSHIP = `${BASE_API_URL_DEVELOPMENT}/mbs/members/{id}/removecancel`;
export const API_GET_CANCEL_CODE = `${BASE_API_URL_DEVELOPMENT}/mbs/removecancelreasons`;

// selling price
export const API_ACTION_ON_SELLING_PRICE_ITEM = `${BASE_API_URL_DEVELOPMENT}/plm/fees/{id}/sellingprices/{sellingpriceid}`;
export const API_GET_SELLING_PRICES_SUBTENANTS = `${BASE_API_URL_DEVELOPMENT}/plm/subtenants`;
export const API_GET_SELLING_PRICES_CLUBGROUPS = `${BASE_API_URL_DEVELOPMENT}/plm/clubgroups`;
export const API_GET_SELLING_PRICES_PRIORITIS = `${BASE_API_URL_DEVELOPMENT}/plm/fees/priorities`;
export const API_SAVE_SELLING_PRICES = `${BASE_API_URL_DEVELOPMENT}/plm/fees/{id}/sellingprices`;
